<template>
  <section
    id="terms"
  >
    <v-img
      style="height: 60px; background-color: #234e79; background-image: linear-gradient(110deg, #234e79 0%, #9895e2 100%);"
    />
    <v-container>
      <v-card
        flat
        min-height="300"
        class="pa-4"
      >
        <v-card-text>
          <div
            class="text-center"
          >
            <h2>LAVVIRA TERMS AND CONDITIONS OF USE</h2>
          </div>

          <div
            class="mt-4"
            style="font-size: 16px;"
          >
            <p class="mt-12">
              Continued browsing of this Site implies unreserved acceptance of the following provisions and conditions of use.
              The version of these terms and conditions of use currently online is the only version that may be invoked throughout the period of use of the Site and until a new version replaces it.
            </p>

            <h3>Article 1 - Legal information</h3>

            <p class="mt-2 ml-4">
              <span class="font-weight-bold">1.1</span> This website (hereinafter the " Website "), which can be accessed at www.lavvira.net, is published by LAVVIRA, a limited liability company under Bulgarian law,
              whose registered office is at Sofia, Vitosha, str. Boris Arsov no. 7, registered with the Bulgarian Trade and Companies Register under number 206144447, hereinafter "LAVVIRA").
            </p>
            <p class="ml-4">
              E-mail address : support@lavvira.net
            </p>
            <p class="ml-4">
              Director of publication : Boyan PETROV
            </p>

            <p class="mt-2 ml-4">
              <span class="font-weight-bold">1.2</span> Design and production: LAVVIRA
            </p>

            <p class="mt-2 ml-4">
              <span class="font-weight-bold">1.3</span> Host (hereinafter the "Host") : AWS, AMAZON WEB SERVICES EMEA SARL,
              with share capital of 25,000 euros,
              whose registered office is located at 38 avenue John F. Kennedy, 1855, Luxembourg
              registered in the Trade and Companies Register under number 831 001 334.
            </p>

            <p class="ml-4">
              E-mail address:
              <a
                href="https://aws.amazon.com/contact-us/"
                target="_blank"
              >
                https://aws.amazon.com/contact-us/
              </a>
            </p>

            <p class="ml-4">
              Website:
              <a
                href="https://aws.amazon.com/"
                target="_blank"
              >
                https://aws.amazon.com/
              </a>
            </p>

            <h3>Article 2 - Website access</h3>

            <p class="mt-4">
              Access to the Website is reserved for persons of legal age and capacity.
            </p>

            <p>
              Access to and use of the Website is strictly personal.
              You undertake not to use this Website or the information or data contained therein for commercial,
              political or advertising purposes or for any form of commercial solicitation,
              in particular the sending of unsolicited electronic mail.
            </p>

            <h3>Article 3 - Website content</h3>
            <p class="mt-4">
              All trademarks, photographs, texts, comments, illustrations,
              images (animated or not), video sequences, sounds,
              as well as all computer applications that may be used to operate this Website and,
              more generally, all elements reproduced or used on the Site are protected by current intellectual property laws.
            </p>

            <p>
              They are the full and entire property of LAVVIRA or its partners.
              Any reproduction, representation, use or adaptation, in any form whatsoever,
              of all or part of these elements, including computer applications,
              without the prior written consent of LAVVIRA, is strictly prohibited.
              The fact that LAVVIRA does not initiate proceedings as soon as it becomes
              aware of such unauthorised use does not constitute acceptance of said use and waiver of legal action.
            </p>

            <h3>Article 4 - Website management</h3>
            <p class="mt-4">
              For the proper management of the Website, LAVVIRA may at any time:
            </p>
            <ul class="mb-4">
              <li>suspend, interrupt or limit access to all or part of the Website, restrict access to the Website, or to certain parts of the Website, to a specific category of Internet user;</li>
              <li>delete any information that may disrupt the operation of the Website or contravene national or international laws or the rules of good conduct on the Internet;</li>
              <li>suspend the Website in order to update it.</li>
            </ul>

            <h3>Article 5 - Responsibilities</h3>

            <p class="mt-4">
              LAVVIRA may not be held liable in the event of any failure, breakdown,
              difficulty or interruption in operation,
              preventing access to the Website or to any of its functionalities.
            </p>

            <p>
              You are entirely responsible for the equipment you use to connect to the Website.
              You must take all appropriate measures to protect your equipment and your own data,
              in particular from viral attacks via the Internet.
              You are also solely responsible for the websites and data that you consult.
            </p>

            <p>LAVVIRA cannot be held responsible in the event of legal proceedings against you:</p>
            <ul>
              <li>as a result of the use of the Website or any service accessible via the Internet;</li>
              <li>as a result of your failure to comply with these general conditions of use.</li>
            </ul>

            <p class="mt-4">
              LAVVIRA is not responsible for any damage caused to you,
              to third parties and/or to your equipment as a result of your connection
              to or use of the Website and you hereby waive any claim against LAVVIRA in this respect.
            </p>

            <p>
              Should LAVVIRA be the subject of amicable or legal proceedings as a result of your use of the Website,
              it may take action against you to obtain compensation for all damages, sums,
              fines and costs that may arise from such proceedings.
            </p>

            <h3>Article 6 - Hypertext links</h3>

            <p class="mt-4">
              The creation by you of any hypertext links to all or part of the Website
              is strictly forbidden without the prior written authorisation of LAVVIRA,
              which may be obtained by sending an e-mail to the following address: <a href="mailto: support@lavvira.net">support@lavvira.net</a>.
            </p>

            <p>
              LAVVIRA is free to refuse such authorisation without having to justify its decision in any way whatsoever.
              In the event that LAVVIRA grants its authorisation, this authorisation is in all cases only temporary
              and may be withdrawn at any time, without any obligation on LAVVIRA to justify its decision.
            </p>

            <p>In all cases, any link must be withdrawn on simple request from LAVVIRA.</p>

            <p>
              Any information accessible via a link to other Websites is not under the control of LAVVIRA,
              which declines all responsibility for their content.
            </p>

            <h3>Article 7 - Protection of personal data</h3>

            <p class="mt-4">
              In the course of its business, LAVVIRA collects several types of data.
            </p>

            <p>
              This collection is systematically based on the consent
              of the person concerned or on a legal obligation.
            </p>

            <p>It is restricted to data that is strictly necessary for LAVVIRA's activity.</p>

            <p>
              You can read our Personal Data Protection Policy at the following link
              <a
                href="https://www.lavvira.net/data-protection-policy/"
                target="_blank"
              >
                https://www.lavvira.net/data-protection-policy/
              </a>.
              It complements our General Terms and Conditions of Sale
              <a
                href="https://www.lavvira.net/terms-and-conditions-of-sale/"
                target="_blank"
              >
                https://www.lavvira.net/terms-and-conditions-of-sale/
              </a>
              and our Cookies Policy
              <a
                href="https://www.lavvira.net/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lavvira.net/cookie-policy/
              </a>.
            </p>

            <p>
              LAVVIRA reserves the right to update its Personal Data Protection Policy
              and will indicate any updates via the Site and in particular by displaying a new updated banner.
            </p>

            <h3>Article 8 - Cookies</h3>

            <p class="mt-4">
              LAVVIRA uses cookies and similar technologies to enable and facilitate your browsing,
              to personalise the content offered to you or to compile visit statistics.
            </p>

            <p>
              You can read our Cookie Policy at the following link
              <a
                href="https://www.lavvira.net/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lavvira.net/cookie-policy
              </a>,
              which explains how and why LAVVIRA uses these technologies and the choices available to you.
              It complements the Personal Data Protection Policy
              (<a
                href="https://www.lavvira.net/terms-and-conditions-of-sale/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lavvira.net/terms-and-conditions-of-sale/
              </a>.)
            </p>

            <p>You can modify your choice of cookies, accept or refuse cookies at any time by clicking on Cookie Management.</p>

            <p>
              LAVVIRA reserves the right to update its Cookie Policy
              and will indicate any updates throughout the Website,
              in particular by displaying a new updated banner.
            </p>

            <h3>Article 9 - General terms and conditions of sale</h3>

            <p class="mt-4">
              These General Terms and Conditions of Use of the Website
              form an integral part of our General Terms and Conditions of Sale.
            </p>

            <p>
              You can read our General Terms and Conditions of Sale
              at the following link
              <a
                href="https://www.lavvira.net/terms-and-conditions-of-sale/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lavvira.net/terms-and-conditions-of-sale/
              </a>.
            </p>

            <h3>Article 10 - Applicable law</h3>

            <p class="mt-4">
              These General Terms and Conditions of Use of the Site are governed by Bulgarian law
              and are subject to the jurisdiction of the courts of Sofia,
              subject to any specific attribution of jurisdiction arising from a particular legal or regulatory text.
            </p>

            <h3>Article 11 - Contact us</h3>

            <p class="mt-4">
              If you have any questions or require information about the products presented on the Website,
              or about the Website itself, you can contact us in writing at the following address:
              LAVVIRA - Sofia, Vitosha, str. Boris Arsov n°7 or by e-mail at the following address:
              <a href="mailto:support@lavvira.net">support@lavvira.net</a>.
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </section>
</template>

<script>
  export default {}
</script>
